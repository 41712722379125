import { graphql, PageProps } from 'gatsby';
import React from 'react';
import { Page } from '../components/Page';
import { PageTitle } from '../components/PageTitle';
import { SectionContainer, SectionContent } from '../components/section';
import { UserAgreementQuery } from '../generated/graphqlTypes';

export type Props = Pick<PageProps<UserAgreementQuery>, 'data'>;

const UserAgreement: React.FC<Props> = ({ data }) => {
  const userAgreement = data?.datoCmsUserAgreement;
  if (!userAgreement) throw new Error('No User Agreement');

  return (
    <Page>
      <PageTitle>{userAgreement.title}</PageTitle>
      <SectionContainer>
        <SectionContent isSmallMaxWidth={true}>
          <div
            dangerouslySetInnerHTML={{
              __html: userAgreement.descriptionNode?.childMarkdownRemark?.html ?? '',
            }}
          />
        </SectionContent>
      </SectionContainer>
    </Page>
  );
};

export default UserAgreement;

export const query = graphql`
  query UserAgreement {
    datoCmsUserAgreement {
      title
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
